import { InputAdornment, TextareaAutosize } from "@material-ui/core";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  EditableText,
  FlashMessage,
  Input,
  Modal,
  Radio,
  SelectNew,
} from "../../components/common";
import { useForm } from "react-hook-form";
import httpRequest from "../../utils/httpRequest";
import API_URLS from "../../utils/apiUrls";
import {
  EMPLOYEE_SIZE_OPTIONS,
  Hiring_Type,
  INDUSTRY_OPTIONS,
} from "../../utils/appConstants";
import { useHistory, useLocation } from "react-router-dom";
import AutoModeIcon from "@material-ui/icons/Loop";
import salesInfoImg from "../../assets/images/sales-info.png";
import salesInfoMobile from "../../assets/images/sales-info-mobile.png";
import emailThankyouImg from "../../assets/images/email-thankyou-icon.png";
import appRoutes from "../../routes/app.routes";

const ContactUsSales = () => {
  const {
    register,
    handleSubmit,
    errors,
    control,
    reset: resetForm,
  } = useForm({});

  const location = useLocation();
  const path = location.pathname;
  const history = useHistory();
  const [hiringType, setHiringType] = useState<string>("");
  const [captchaImage, setCaptchaImage] = useState("");
  const [captchaAnswer, setCaptchaAnswer] = useState();
  const [services, setServices] = useState<string[]>([]);
  const [message, setMessage] = useState("");
  const [successModal, setSuccessModal] = useState<boolean>(false);
  const [submitLoader, setSubmitLoader] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string>(salesInfoImg);

  useEffect(() => {
    const updateImage = () => {
      if (window.innerWidth <= 991) {
        setImageSrc(salesInfoMobile);
      } else {
        setImageSrc(salesInfoImg);
      }
    };

    updateImage(); // Initial check
    window.addEventListener("resize", updateImage); // Listen for window resize

    return () => window.removeEventListener("resize", updateImage); // Cleanup
  }, []);
  
  const staffingList = [
    "Enhance and expand your current workforce",
    "Provide specialized expertise for short or long-term projects",
    "Recruit and screen top-tier talent for permanent roles",
  ];
  const designBuildList = [
    "Network architecture and deployment",
    "Application development and optimization",
    "Web development and testing",
    "Deployment planning and migration",
  ];

  useEffect(() => {
    if (path === "/contact-us/sales") {
      createCaptcha();
    }
  }, [path]);

  const SaveReview = async (formData) => {
    const captcha = formData.captcha_answer;
    setSubmitLoader(true);
    if (captcha === captchaAnswer) {
      const payload = {
        name: formData.name,
        company_name: formData.company_name,
        company_email: formData.company_email,
        company_size: parseInt(formData.company_size.value),
        contact_info: formData.contact_info,
        company_url: formData.company_url,
        industry_id: parseInt(formData.industry.value),
        services: services,
        hiring_type: hiringType,
        message: message,
      };

      try {
        const response = await httpRequest().post(
          `${API_URLS.Inquiry}`,
          payload
        );
        if (response.data?.status === 200) {
          FlashMessage(response.data?.message);
          resetForm({
            name: "",
            company_name: "",
            company_email: "",
            company_size: "",
            contact_info: "",
            company_url: "",
            industry: "",
            captcha_answer: "",
          });
          setServices([]);
          setMessage("");
          setSuccessModal(true);
          setSubmitLoader(false);
        }
        return response;
      } catch (error) {
        const errorList = error?.response?.data?.errors || {};
        setSubmitLoader(false);
        if (Object.values(errorList).length > 0) {
          const firstError = Object.values(errorList)[0] as string;
          FlashMessage(firstError, "error");
        } else {
          FlashMessage(error.response.data.message, "error");
        }
      }
    } else {
      setSubmitLoader(false);
      FlashMessage("Please Enter Valid Captcha", "error");
    }
  };

  const createCaptcha = async () => {
    const data = await httpRequest().post(API_URLS.captcha);
    const svgImage = data.data.data.data;
    const responseCaptchaAnswer = data.data.data.text;
    setCaptchaAnswer(responseCaptchaAnswer);
    setCaptchaImage(svgImage);
  };

  const handleServiceChange = (e: any) => {
    const { name, checked } = e.target;
    setServices((prev) =>
      checked ? [...prev, name] : prev.filter((service) => service !== name)
    );
  };

  return (
    <div className="main-inquiry-container">
      <div className="left-side">
        <img src={imageSrc} alt="sales-info" />
      </div>
      <div className="right-side">
        <h2>How Can We Help?</h2>
        <p>Reach out, and we’ll get back to you within 24 hours!</p>
        <form noValidate onSubmit={(e) => handleSubmit(SaveReview)(e)}>
          <div className="form-wrapper">
            <div className="form-group two-cols">
              <div className="form-field">
                <Input
                  name="name"
                  externalLabel={{ label: "Customer Name" }}
                  placeholder="Full name"
                  validationObj={errors}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter Full Name",
                    },
                  })}
                />
              </div>
              <div className="form-field">
                <Input
                  name="company_name"
                  externalLabel={{ label: "Company Name" }}
                  placeholder="Company Name"
                  validationObj={errors}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter Company Name",
                    },
                  })}
                />
              </div>
            </div>
            <div className="form-group">
              <Input
                name="company_email"
                type="email"
                externalLabel={{ label: "Company Email Address" }}
                placeholder="Email address"
                validationObj={errors}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Please enter Your Company Email",
                  },
                })}
              />
            </div>
            <div className="form-group two-cols">
              <div className="form-field">
                <SelectNew
                  name="company_size"
                  externalLabel={{ label: "Company size" }}
                  placeholder="select your size"
                  className="select-box"
                  options={EMPLOYEE_SIZE_OPTIONS}
                  isSearchable={true}
                  validationObj={errors}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter company employee size",
                    },
                  }}
                />
              </div>
              <div className="form-field">
                <label>Phone Number</label>
                <EditableText
                  inputClassName="editable-input"
                  control={control}
                  name="contact_info"
                  country="us"
                  error={errors.contact_info}
                  rules={{
                    required: {
                      value: true,
                      message: "Phone is required",
                    },
                  }}
                />
              </div>
            </div>
            <div className="form-group">
              <Input
                name="company_url"
                externalLabel={{ label: "Company Website" }}
                placeholder="website URL"
                validationObj={errors}
                inputRef={register({
                  required: {
                    value: true,
                    message: "Please enter ",
                  },
                })}
              />
            </div>
            <div className="form-group two-cols">
              <div className="form-field">
                <SelectNew
                  name="industry"
                  externalLabel={{ label: "Industry" }}
                  placeholder="select your Industry"
                  className="select-box"
                  options={INDUSTRY_OPTIONS}
                  isSearchable={true}
                  validationObj={errors}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: "Please enter industry",
                    },
                  }}
                />
              </div>
              <div className="radio-wrapper">
                <label>Hiring Type</label>
                <Radio
                  name="hiring_type"
                  control={control}
                  validationObj={errors}
                  labelPlacement="end"
                  wrapperClassName="gender-radio-wrapper w-100"
                  labelClassName="gender-radio"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setHiringType(_.get(e, "target.value", ""));
                  }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please select a role",
                    },
                  })}
                  options={Hiring_Type}
                />
              </div>
            </div>
            <div className="checkbox-card-wrapper">
              <p>
                We’re here to support your growth and success. Let us know how
                we can assist!
              </p>
              <div className="form-group two-cols">
                <div
                  className={`form-field checkbox-card ${
                    services.includes("staffing_solution") ? "active" : ""
                  }`}
                >
                  <Checkbox
                    name="staffing_solution"
                    externalLabel={{
                      label: "Staffing Solutions",
                    }}
                    color="primary"
                    inputRef={register()}
                    onChange={handleServiceChange}
                  />
                  <p>
                    Our employHER Teams™ are ready to step in and drive your
                    business success. Whether you need contingent staff or
                    permanent hires, rely on us to
                  </p>
                  <ul>
                    {staffingList.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
                <div
                  className={`form-field checkbox-card ${
                    services.includes("design_build_solution") ? "active" : ""
                  }`}
                >
                  <Checkbox
                    name="design_build_solution"
                    externalLabel={{
                      label: "Design & Build Solution",
                    }}
                    color="primary"
                    wrapperClassName="gender-radio-wrapper w-100"
                    labelClassName="gender-radio"
                    inputRef={register()}
                    onChange={handleServiceChange}
                  />
                  <p>
                    Our employHER Teams™ design, build, and optimize technology
                    and business processes to meet your goals. Trust us to
                    deliver exceptional results in:
                  </p>
                  <ul>
                    {designBuildList.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
            <div className="form-group">
              <div className="form-field">
                <label> Message</label>
                <TextareaAutosize
                  minRows={7}
                  name="message"
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="How did you hear about employHER?"
                />
              </div>
            </div>
            <div className="form-group">
              <div className="captcha-view-sales">
                <div
                  dangerouslySetInnerHTML={{
                    __html: captchaImage,
                  }}
                />

                <Input
                  name="captcha_answer"
                  placeholder="Enter Captcha Answer"
                  validationObj={errors}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AutoModeIcon
                          className="captcha-icon"
                          onClick={() => createCaptcha()}
                        />
                      </InputAdornment>
                    ),
                  }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter captcha answer",
                    },
                    pattern: {
                      value: /[0-9]+/,
                      message: "Enter valid captcha answer",
                    },
                    validate: (value) =>
                      value === captchaAnswer ||
                      "The verify captcha do not match",
                  })}
                />
              </div>
            </div>
            <div className="form-group authorize">
              <Checkbox
                name="authorize"
                externalLabel={{
                  label:
                    "I authorize employHER to store and retain my personal data. By providing your phone number and other information, you agree to be contacted via email, phone, and SMS.",
                }}
                color="primary"
                wrapperClassName="gender-radio-wrapper w-100"
                labelClassName="gender-radio"
                inputRef={register()}
                onChange={handleServiceChange}
              />
            </div>
            <div className="form-actions">
              <Button type="submit" color="primary" loading={submitLoader}>
                Submit
              </Button>
            </div>
          </div>
        </form>
      </div>
      <Modal
        className="sales-success-modal"
        visible={successModal}
        size={"large"}
        closeButton={true}
        closeOnBackDrop={successModal}
        onClose={() => setSuccessModal(false)}
      >
        <div className="modal-head">
          <img src={emailThankyouImg} alt="email thankyou" />
        </div>
        <div className="modal-content-wrapper">
          <div className="modal-content">
            <h3>Thank you for Contacting</h3>
            <p>
              Your message has been sent to our Sales and Recruiting Team. We’ll
              get back to you within 24 hours.
            </p>
            <Button
              type="button"
              color="primary"
              onClick={() => {
                history.push(appRoutes.contactUs.path);
                setSuccessModal(false);
              }}
            >
              Close
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ContactUsSales;
