/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { InputAdornment } from "@material-ui/core";
import _ from "lodash";
import LogRocket from "logrocket";
import Smartlook from "smartlook-client";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Button, Input, Modal } from "../../../components/common";
import {
  userLoginRequest,
  resetLoginFlag,
  userLoginSuccess
} from "../../../reducers/auth/login.reducer";
import {
  setCurrentCompanyRequest,
  setSessionTokenRequest,
  setSessionUserRequest,
} from "../../../reducers/auth/session.reducer";
import appRoutes from "../../../routes/app.routes";
import { ReactComponent as PersonIcon } from "../../../assets/svg/person.svg";
import { ReactComponent as LockIcon } from "../../../assets/svg/lock.svg";
import Affirmation from "../../../components/common/Affirmation";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { COMPANY_FLAG } from "../../../utils/appConstants";
import httpRequest from "../../../utils/httpRequest";
import API_URLS from "../../../utils/apiUrls";
import verificationImage from "../../../assets/svg/twofa.svg";
import CodeEnter from "../../../components/common/CodeEnter";

type Inputs = {
  email: string;
  password: string;
};

const Login: React.FC = () => {
  const logRocketAppId = process.env.REACT_APP_LOG_ROCKET;
  const smartLookAppId = process.env.REACT_APP_SMART_LOOK;
  const [twoFA, setTwoFA] = useState(false);
  const [open, setOpen] = useState(false);
  const [inputValues, setInputValues] = useState(Array(6).fill(''));
  const [tokenVerifyError, setTokenVerifyError] = useState('')
  const [clearFlag, setClearFlag] = useState(false);
  const [recoveryModal, setRecoveryModal] = useState(false);
  const [recoveryKey, setRecoveryKey] = useState("");
  const [recoveryVerifyError, setRecoveryVerifyError] = useState("");
  const [isLoadVerify, SetIsLoadVerify] = useState(false);


  const history = useHistory();
  const dispatch = useDispatch();
  const {
    loading,
    message: loginError,
    flag: loginFlag,
    data,
  } = useSelector(({ auth }: any) => auth.login);

  const { register, handleSubmit, errors } = useForm<Inputs>();
  const userToken = data?.token

  useEffect(() => {
    if (data?.twoFAVerificationRequired) {
      setTwoFA(true)
      setOpen(true)
      setClearFlag(false)
    } else {
      if (loginFlag === true) {
        const token = _.get(data, "token", null);
        const sessionData = _.get(data, "session", {});

        // Set session data
        dispatch(setSessionTokenRequest(token));
        dispatch(setSessionUserRequest(sessionData));

        // Navigate user based on role
        const IS_COMPANY =
          _.get(data, "session.is_company") === COMPANY_FLAG.YES ? true : false;
        let companyDetails =
          sessionData?.companies?.find((row) => row.privileges === true) || null;

        // Check for first company access
        if (!companyDetails) {
          companyDetails = sessionData?.companies[0] || {};
        }

        const companySlug = companyDetails.slug || "";
        const userId = sessionData.id;
        const email = sessionData.email;
        const firstName = sessionData.first_name;

        if (IS_COMPANY && companySlug) {
          // LogRocket User Identity
          if (logRocketAppId) {
            LogRocket.identify(logRocketAppId, {
              name: firstName,
              email: email,
              companySlug: companySlug,
            });
          }

          // Smartlook User Identity
          if (smartLookAppId) {
            Smartlook.identify(userId, {
              name: firstName,
              email: email,
              companySlug: companySlug,
            });
          }

          // Set the company
          dispatch(setCurrentCompanyRequest(companyDetails));
          const companyPath =
            appRoutes.companyDashboard.generatePath(companySlug);
          history.push(companyPath);
        } else {
          // LogRocket User Identity
          if (logRocketAppId) {
            LogRocket.identify(logRocketAppId, {
              name: firstName,
              email: email,
            });
          }

          // Smartlook User Identity
          if (smartLookAppId) {
            Smartlook.identify(userId, {
              name: firstName,
              email: email,
            });
          }
          history.push(appRoutes.candidateProfileView.path);
        }

        dispatch(resetLoginFlag());
      }
    }

  }, [loginFlag, data, dispatch, history]);


  const verifyToken = async () => {
    SetIsLoadVerify(true);

    try {
      if (!inputValues.includes('')) {
        const verifyCode = inputValues.join('');
        const payload = {
          token: verifyCode,
          userToken: userToken
        };
        const data = await httpRequest().post(API_URLS.verifyToken, payload);

        if (data.data.data.delta === 0) {
          // Successful verification, dispatch login success action
          dispatch(userLoginSuccess({
            messages: data.data.message,
            data: data.data.data
          }));
        } else {
          setTokenVerifyError('Enter valid verification code');
        }
      } else {
        setTokenVerifyError("Please enter 6 digit verification code");
      }
    } catch (error) {
      setTokenVerifyError("An error occurred while verifying the code. Please try again.");
    } finally {
      SetIsLoadVerify(false);
    }
  };


  const verifyRecoveryKey = async () => {
    SetIsLoadVerify(true);
    try {
      const payload = {
        recoveryKey: recoveryKey,
        userToken: userToken,
      };
      const response = await httpRequest().post(API_URLS.verifyRecovery, payload);

      if (response.data.data.isVerify) {
        dispatch(userLoginSuccess({
          messages: response.data.message,
          data: response.data.data,
        }));
        setRecoveryKey("");
        setRecoveryModal(false);
        setRecoveryVerifyError("");
      }
    } catch (error) {
      setRecoveryVerifyError(error.response.data.data.message);
    }
    finally {
      SetIsLoadVerify(false)
    }
  };


  const handleClose = () => {
    setOpen(false);
    setInputValues(Array(6).fill(''));
    setTokenVerifyError('')
    setClearFlag(true);
  }

  const handleInputChange = (index, value) => {
    if (value.length === 1) {
      setInputValues(prevValues => {
        const newValues = [...prevValues];
        newValues[index] = value;
        return newValues;
      });
    }
  };

  const onSubmit = async (formData) => {
    dispatch(
      userLoginRequest({
        email: formData.email,
        password: formData.password,
        action: "login",
        isVerify: false
      })
    );
  };


  useEffect(() => {
    if (!inputValues.includes('') && inputValues.length === 6) {
      verifyToken();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValues])

  return (
    <>
      <div className="left-part">
        <Affirmation />
      </div>
      <div className="right-part">
        <div className="">
          <div className="back-website">
            <div className="back">
              <Link to={appRoutes.home.path}>
                {" "}
                <ArrowBackIosIcon />{" "}
              </Link>
            </div>
            <Link to={appRoutes.home.path}>Back to website</Link>
          </div>
          <div className="auth-card auth-login-wrapper">
            <h2 className="auth-card-title">Login</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="form-group">
                <p className="form-group-label">Email</p>
                <Input
                  name="email"
                  placeholder="Email"
                  validationObj={errors}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PersonIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter email address",
                    },
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Enter valid email address",
                    },
                  })}
                />
              </div>
              <div className="form-group">
                <p className="form-group-label">Password</p>
                <Input
                  type="password"
                  name="password"
                  placeholder="Password"
                  validationObj={errors}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <LockIcon />
                      </InputAdornment>
                    ),
                  }}
                  inputRef={register({
                    required: {
                      value: true,
                      message: "Please enter password",
                    },
                    minLength: {
                      value: 4,
                      message: "Password must be between 4 to 16 characters",
                    },
                  })}
                />
                {!twoFA && loginError && (
                  <span className="text-sm text-danger error-helper">
                    {loginError}
                  </span>
                )}
                {loginError && (
                  <span className="text-sm text-danger error-helper">
                    {loginError}
                  </span>
                )}

              </div>
              {twoFA && (
                <>
                  <Modal
                    className="login-verification"
                    visible={open}
                    onClose={() => handleClose()}
                  >
                    <h2 className="text-qr">Two-Step Verification</h2>
                    <div className="email-img" ><img src={verificationImage} alt="" /></div>
                    <div className="info">Enter your code to complete Login </div>
                    <div className="info"><span>Open the verification app on your phone <br /> to get your verification app</span></div>

                    <CodeEnter
                      clear={clearFlag}
                      handleOnChange={(index, e) => handleInputChange(index, e.target.value)}
                    />

                    <div className="error-verify">
                      {tokenVerifyError && (
                        <span className="text-sm text-danger error-helper">
                          {tokenVerifyError}
                        </span>
                      )}
                    </div>
                    <div>
                      <Button
                        onClick={() => verifyToken()}
                        color="primary"
                      >
                        Login
                      </Button>
                    </div>
                    <p
                      className="authentication-link"
                      onClick={() => {
                        setRecoveryModal(true)
                        setOpen(false)
                      }
                      }>Haven't Device,Use recovery Key.</p>

                  </Modal>

                </>
              )}
              {recoveryModal && (
                <>
                  <Modal
                    className="login-verification"
                    visible={recoveryModal}
                    onClose={() => {
                      setRecoveryModal(false);
                      setRecoveryVerifyError("");
                    }}
                  >
                    <h2 className="text-qr">Enter Your Recover Key</h2>
                    <Input
                      type="text"
                      name="recovery-key"
                      placeholder="Enter your recovery key"
                      validationObj={errors}
                      onChange={(e) => setRecoveryKey(e.target.value)}
                      inputRef={register({
                        required: {
                          value: true,
                          message: "Please enter Recovery Key",
                        },
                      })}
                    />
                    <div className="error-verify">
                      {recoveryVerifyError && (
                        <span className="text-sm text-danger error-helper">
                          {recoveryVerifyError}
                        </span>
                      )}
                    </div>
                    <div>
                      <Button
                        onClick={() => verifyRecoveryKey()}
                        color="primary"
                        loading={isLoadVerify}
                      >
                        Login
                      </Button>
                    </div>
                    <p
                      className="authentication-link"
                      onClick={() => {
                        setRecoveryModal(false);
                        setOpen(true);
                      }}
                    >
                      Haven Device? Use authentication Code.
                    </p>
                  </Modal>
                </>
              )}

              <div className="form-group btn-group">
                <Button type="submit" color="primary" loading={loading}>
                  Login
                </Button>
              </div>
              <div className="form-group text-center">
                <Link to={appRoutes.userForgotPassword.path}>
                  {appRoutes.userForgotPassword.title}
                </Link>
              </div>
            </form>
          </div>
          <p className="outer-link">
            If you don’t have an account?{" "}
            <Link to={appRoutes.userSignUp.path}>
              {appRoutes.userSignUp.title}
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default Login;
